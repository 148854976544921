<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-hummer"> </i> 维修管理 | 人员管理</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增人员</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.ROLE" placeholder="请选择角色" clearable style="margin-left: 5px">
                <el-option :label="it.TXT" :value="it.ROLE" v-for="it in roles" :key="it.ROLE" />
              </el-select>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1"
              :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
              <el-table-column label="角色" width="120" align="center">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.ROLE=='EXTERNAL'">{{scope.row.ROLE_TXT}}</el-tag>
                  <span v-else> {{scope.row.ROLE_TXT}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="NAME" label="姓名" width="120" align="center" />
              <el-table-column prop="MOBILE" label="手机" width="150" align="center" />
              <el-table-column prop="MEM" label="备注/服务单位" width="200" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="mender_edit" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
  import Edit from "./edit";
  import Wgrid from "@/components/wgrid";
  export default {
    name: "index",
    components: { Wgrid, Edit },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
          },
          ls: [],
          total: 0,
          loading: false
        },
        roles: [{ ROLE: 'ADMIN', TXT: '维修管理' },
        { ROLE: 'REPAIR', TXT: '维修人' },
        { ROLE: 'COPY', TXT: '抄送人' },
        { ROLE: 'EXTERNAL', TXT: '外部人员' }],
      }
    },
    created() {
      this.filter();
    },
    methods: {
      filter() {
        // this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/FIX/MenderApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
          }
        })
      },
      rowEdit(r) {
        this.$refs.mender_edit.init(r);
      },
      rowDel(r) {
        this.whale.delConfirm(() => {
          let self = this;
          this.whale.remote.getResult({
            url: "/api/School/FIX/MenderApi/Delete",
            data: { ID: r.ID },
            completed: function () {
              self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
            }
          })
        });
      },
      // tableRowClassName({ row, rowIndex }) {
      //   if (row.ROLE == 'EXTERNAL') {
      //     return 'external';
      //   }
      //   return '';
      // }
    }
  }
</script>